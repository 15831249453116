import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Hosted / SaaS`}</h1>
    <ul>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{` is `}<a parentName="li" {...{
          "href": "/hosted",
          "title": "hosted"
        }}>{`hosted`}</a>{` as Software as a Service at `}<a parentName="li" {...{
          "href": "/pol.is",
          "title": "pol.is"
        }}>{`pol.is`}</a>{``}</li>
      <li parentName="ul">{`See `}<a parentName="li" {...{
          "href": "/deploying-Polis",
          "title": "💾 deploying Polis"
        }}>{`💾 deploying Polis`}</a>{``}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      